<template>
	<div class="app-container" v-loading="loading">
		<el-header class="new-header">
			<div class="new-header-left">
				<span>新建客户</span>
				<span class="back" @click="Detailsbacktolist">返回</span>
			</div>
		</el-header>
		<el-main>
			<el-row class="new-row-header">
				<el-col :span="8">
					<span class="left-border-title">基本信息</span>
				</el-col>
			</el-row>
			<el-row class="new-row-main">
				<el-form
					:model="formInline"
					ref="form"
					:rules="rules"
					class="demo-form-inline"
					label-width="160px"
				>
					<el-row>
						<el-col :span="8">
							<el-form-item label="客户姓名：" prop="customerName">
								<el-input
									v-model="formInline.customerName"
									placeholder=" 客户姓名"
								></el-input>
							</el-form-item>
              <el-form-item label="客户状态：" prop="customerState">
                <el-select
                    v-model="formInline.customerState"
                    filterable
                    clearable
                    placeholder="请选择客户状态"
                    @clear="formInline.customerState = null"
                >
                  <el-option
                      v-for="item in customerStateList"
                      :key="item.id"
                      :label="item.value"
                      :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
							<el-form-item label="所在区域：" prop="">
								<el-cascader
									v-model="formInline.selectedOptions"
									:options="cityoptions"
									:props="{
										value: 'id',
										label: 'name',
										children: 'children',
										checkStrictly: false,
									}"
								></el-cascader>
							</el-form-item>
							<el-form-item label="公司名称：" prop="companyName">
								<el-input
									v-model="formInline.companyName"
									placeholder="公司名称"
								></el-input>
							</el-form-item>
							<el-form-item label="行业：" prop="industry">
								<el-input
									v-model="formInline.industry"
									placeholder="行业"
								></el-input>
							</el-form-item>
							<!-- <el-form-item label="标签：" prop="tagList">
                <el-select v-model="formInline.intentionTag" placeholder="标签">
                  <el-option
                    v-for="item in customerTypeList"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item> -->
							<el-form-item label="备注：" prop="remark">
								<el-input
									v-model="formInline.remark"
									placeholder="备注"
									type="textarea"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="8">
							<el-form-item label="手机号：" prop="customerPhone">
								<el-input
									v-model="formInline.customerPhone"
									placeholder="手机号"
								></el-input>
							</el-form-item>
							<el-form-item label="详细地址：">
								<el-input
									v-model="formInline.address"
									placeholder="详细地址"
								></el-input>
							</el-form-item>
							<el-form-item label="职业：" prop="profession">
								<el-input
									v-model="formInline.profession"
									placeholder=" 职业"
								></el-input>
							</el-form-item>
							<el-form-item label="销售：" v-if="hasSales">
								<el-select
									v-model="formInline.salesId"
									filterable
									clearable
									placeholder="请选择销售人员"
									@clear="formInline.salesId = null"
								>
									<el-option
										v-for="item in sales"
										:key="item.userId"
										:label="item.userName"
										:value="item.userId"
									>
										<span style="float: left">{{ item.userName }}</span>
										<span
											style="float: right; color: #8492a6; font-size: 13px"
											>{{ item.phone }}</span
										>
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="客户来源：" prop="customerSource">
								<el-select
									v-model="formInline.customerSource"
									filterable
									clearable
									placeholder="请选择客户来源"
									@clear="formInline.customerSource = null"
								>
									<el-option
										v-for="item in customerSourceList"
										:key="item.id"
										:label="item.value"
										:value="item.id"
									></el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
				<el-row type="flex" justify="center">
					<el-col :span="8">
						<el-button @click="submitForm" type="primary">提交</el-button>
						<el-button @click="Detailsbacktolist">取消</el-button>
					</el-col>
				</el-row>
			</el-row>
		</el-main>
	</div>
</template>

<script>
	import {
		createCustomer,
		getUserListOfMerchantByPage,
		initCustomerInfo,
		initRegion,
	} from '../../api'
	export default {
		name: 'AddCustomer',
		data() {
			return {
				loading: false,
				cityoptions: [],
				userId: this.$store.getters.userId,
				customerSourceList: [],
				formInline: {
					userId: this.$store.getters.userId,
					customerPhone: '',
					customerName: '',
					provinceId: '',
					cityId: '',
					districtId: '',
					address: '',
					remark: '',
					intentionTag: '',
					companyName: '',
					profession: '',
					industry: '',
					salesId: null,
					selectedOptions: [],
					customerSource: null,
          customerState: 0,
				},
				customerTypeList: [],
				customerStateList: [],
				sales: [],
        hasSales: true,
				rules: {
					customerPhone: [
						{
							required: true,
							validator: (rule, value, callback) => {
								var istrue = true
								if (value == '') {
									callback(new Error('请输入正确的手机号'))
									istrue = false
								}
								if (value.toString().length !== 11) {
									callback(new Error('请输入正确的手机号'))
									istrue = false
								}
								if (istrue) {
									callback()
								}
							},
							trigger: 'blur',
						},
					],
					selectedOptions: [
						{
							required: true,
							trigger: 'change',
							message: '请选择区域',
						},
					],
          customerState: [
            {
              required: true,
              trigger: 'change',
              message: '请选择客户状态',
            },
          ],
				},
			}
		},
		methods: {
			async initSelectOptions() {
				let data = await initCustomerInfo()
				this.customerStateList = data.customerStateList
				this.customerTypeList = data.customerTypeList
				this.customerSourceList = data.customerSourceList
				let sales = await getUserListOfMerchantByPage({
					current: 1,
					size: 500,
					params: { userId: this.userId },
				})
				this.sales = sales.records
				this.cityoptions = await initRegion()
				this.removeEmptyChildren(this.cityoptions)
			},
			Detailsbacktolist() {
				this.$router.back()
			},
			removeEmptyChildren(node) {
				node.forEach((item) => {
					if ('children' in item && item.children.length === 0) {
						delete item.children
					} else if ('children' in item && item.children.length) {
						this.removeEmptyChildren(item.children)
					}
				})
			},
			submitForm() {
				this.$refs.form.validate((valid) => {
					if (valid) {
						console.log(
							'this.formInline.selectedOptions===========',
							this.formInline.selectedOptions
						)
						if (this.formInline.selectedOptions.length == 3) {
							;[
								this.formInline.provinceId,
								this.formInline.cityId,
								this.formInline.districtId,
							] = this.formInline.selectedOptions
						}
            if(this.$route.query.from){
              if(this.$route.query.from=='customerHighSea'){
                this.formInline.hasHighSea = 1
              } else {
                this.formInline.hasHighSea = 0
              }
            } else {
              this.formInline.hasHighSea = 0
            }
						createCustomer({ ...this.formInline }).then((result) => {
							this.$router.push({
								name: 'customerDetails',
								query: {
									id: result,
								},
							})
						})
					}
				})
			},
		},

		mounted() {},
		created() {
      this.hasSales = !(this.$route.query.from=='customerHighSea')
			this.initSelectOptions()
		},
	}
</script>
<style scoped>
	.back {
		font-size: 12px;
		cursor: pointer;
		color: rgb(64, 158, 255);
		margin-left: 4px;
	}
</style>
